import React, { useEffect } from "react";
import Modal from "react-bootstrap/Modal";

export default function ThankYou({ showThankYouModal, setShowThankYouModal, handleCancel }) {
  const thankyoutick = `${process.env.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_S3_BASE_PATH}/thankyou_tickmark.png`;
  const handleClose = () => {
    setShowThankYouModal(false);
    if (handleCancel) {
      handleCancel();
    }
  };

  useEffect(() => {
    if (showThankYouModal) {
      const timer = setTimeout(() => {
        handleClose();
      }, 10000); // 10 seconds

      // Clear the timer if the modal is closed before 10 seconds
      return () => clearTimeout(timer);
    }
  }, [showThankYouModal]);

  return (
    <div>
      <section className="thankyou_msg_section">
        <Modal className="thankyou_model" show={showThankYouModal} onHide={handleClose}>
          <div className="thankyou_modal_header">
            <Modal.Header closeButton></Modal.Header>
          </div>
          <div className="thankyou_msg_body">
            <Modal.Body>
              <img src={thankyoutick} alt="thankyoutick"></img>
              <h1>Thank you!</h1>
              <span>
                Your submission is received and we will contact you soon.
              </span>
            </Modal.Body>
          </div>
        </Modal>
      </section>
    </div>
  );
}
