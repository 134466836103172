import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import NavbarMenu from "./Components/Layout/NavbarMenu";
import Footer from "./Components/Layout/Footer";
import "./App.css";
import "./Common.css";
import "./Common.scss";
import "./mediaquery.css";
import AppRoutes from "./AppRoutes";
import ReactGA from 'react-ga4';

function App() {
  const location = useLocation();
  useEffect(() => {
    if (window.location.pathname.indexOf('/page-not-found') === -1) {
      ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
    }
  }, [location.pathname]);

  return (
    <>
      <NavbarMenu />
      <AppRoutes />
      <Footer />
    </>
  );
}

export default App;
