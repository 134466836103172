import React from 'react'

export const AchieveCometitiveSection = ({ dataset }) => {
  return (
    <div>
      {dataset?.map(
        (item, index) => (
          <div key={index} className="container">
            <h2>{item?.heading}</h2>
            <div className="row">
              {item?.activeCompetitiveSectionAdvantageSection?.map(
                (dataset, index) => (
                  <div
                    key={index}
                    className="col-lg-4 col-md-4 col-sm-12 achive_sub_main"
                  >
                    <div className="achive_sub_div">
                      <img
                        className="img-fluid"
                        src={dataset?.sectionImg?.url}
                        alt="sectionImg"
                      />
                      <div className="achive_bodydiv">
                        <h1>{dataset?.title}</h1>
                        <p>{dataset?.shortDescription}</p>
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        )
      )}
    </div>
  )
}
