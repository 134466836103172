import React from 'react'

export const WhyDataPreprationDataEngineering = ({ dataset }) => {
  return (
    <div>
      {dataset?.map((item, index) => {
        return (
          <div key={index} className="container">
            <h2 className="whydata_title whydata_prepare_title">
              {item.heading}
            </h2>
            <div className="row align_items_center">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <img
                  className="img-fluid whydata_lft_img"
                  src={item.dataPreparationImage.url}
                  alt="dataPreparationImage"
                />
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12">
                {item?.whyDataProtectionSection?.map((dataset, index) => (
                  <div key={index} className="whydata_rht_main">
                    <div className="whydata_img_div col-lg-2 col-md-2 col-sm-2">
                      <img
                        className="img-fluid"
                        src={dataset?.sectionImg?.url}
                        alt="sectionImg"
                      />
                    </div>
                    <div className="whydata_txt_div col-lg-10 col-md-10 col-sm-10">
                      <h2>{dataset.title}</h2>
                      <p>{dataset?.shortDescription}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )
      }
      )}
    </div>
  )
}
