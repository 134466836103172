import React from "react";
import { Link, useLocation } from "react-router-dom";

const Breadcrumb = ({ navbar, is404, showToggle }) => {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);
  if (location.pathname === "/") {
    return null;
  }

  const transformName = (name) => {
    return name
      .split("-")
      .map((part) => {
        if (part.toLowerCase() === "ai") {
          return "AI";
        } else if (part.toLowerCase() === "ml") {
          return "ML";
        }
        return part.charAt(0).toUpperCase() + part.slice(1);
      })
      .join("-");
  };

  return (
    <div
      className={
        navbar || is404 || showToggle ? "breadcrum-nav active" : "breadcrum-nav"
      }
    >
      <Link to="/">
        <img
          className="home_breadcrumb_icon"
          src={`${process.env.REACT_APP_API_BASE_URL}/${
            process.env.REACT_APP_API_S3_BASE_PATH
          }/${
            navbar || is404 || showToggle
              ? "home_nav_gry.webp"
              : "home_nav_white.webp"
          }`}
          alt="home_icon"
        />
      </Link>
      <img
        className="nav_arrow"
        src={`${process.env.REACT_APP_API_BASE_URL}/${
          process.env.REACT_APP_API_S3_BASE_PATH
        }/${
          navbar || is404 || showToggle ? "path_gry.webp" : "path_white.webp"
        }`}
        alt="arrow"
      />
      {pathnames.map((name, index) => {
        const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
        const isLast = index === pathnames.length - 1;
        const transformedName = transformName(name);

        return (
          <span className="breadcrumb-highlight-text" key={name}>
            {isLast ? (
              <span className="d-inline-block text-truncate align-middle breadcrumb_last_element">
                {transformedName}
              </span>
            ) : (
              <Link
                to={`${routeTo}/`}
                className="d-inline-block text-truncate align-middle breadcrumb_element"
              >
                {transformedName}
              </Link>
            )}
            {isLast ? null : (
              <img
                className="nav_arrow"
                src={`${process.env.REACT_APP_API_BASE_URL}/${
                  process.env.REACT_APP_API_S3_BASE_PATH
                }/${
                  navbar || is404 || showToggle
                    ? "path_gry.webp"
                    : "path_white.webp"
                }`}
                alt="arrow"
              />
            )}
          </span>
        );
      })}
    </div>
  );
};

export default Breadcrumb;
