import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Spinner } from "react-bootstrap";
import { ServerURL } from "../../urls";
import TechnologyStack from "./TechnolgyStack";
import { ServiceBanner } from "./Components/DataEngineering/ServiceBanner";
import { getUpArrowSrc } from "../../utils/getUpArrowSrc";
import ConversationSection from "../Contact/ConversationSection";
const paths = [
  'managed-services', 'real-time-analytics', 'cloud-nativeapp', 'cloud-migration', 'devops-on-cloud'
];
const MainCloudEnablement = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [cloudMigrationDataset, setCloudMigrationDataset] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [scrollActive, setScrollActive] = useState(false);
  const sectionRefs = useRef(null);

  const scrollActiveFun = () => {
    if (window.scrollY >= 100) {
      setScrollActive(true);
    } else {
      setScrollActive(false);
    }
  };

  useEffect(() => {
    scrollActiveFun();
    window.addEventListener("scroll", scrollActiveFun);
    return () => {
      window.removeEventListener("scroll", scrollActiveFun);
    };
  }, []);

  const handleLoadMore = () => {
    sectionRefs.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (!paths.includes(id)) {
      navigate("/page-not-found");
    } else {
      const cloudMigrationServiceURL = `${ServerURL}/mainCloudEnablement/${id}`;
      const fetchData = async () => {
        try {
          const cloudMigrationServiceResponse = await fetch(cloudMigrationServiceURL, {
            credentials: "include", // authentication to access APIs
          }
          );
          const cloudMigrationServiceJSON = await cloudMigrationServiceResponse.json();
          setCloudMigrationDataset(cloudMigrationServiceJSON);
          setIsLoading(false);
        } catch (error) {
          console.log("error", error);
        }
      };

      fetchData();
    }

  }, [id]);

  return (
    <div>
      {isLoading ? (
        <div className="loader-container">
          <Spinner animation="border" variant="primary" />
        </div>
      ) :
        <div ref={sectionRefs}>
          <Helmet>
            <title>{cloudMigrationDataset?.metaTitle}</title>
            <meta name="description" content={cloudMigrationDataset?.metaDescription} />
          </Helmet>
          <ServiceBanner dataset={cloudMigrationDataset} />
          {cloudMigrationDataset?.longDescription &&
            <section className="new_services_ban_btm_section aboutnew_ban_btm_txt_sec">
              <div className="aboutnew_ban_btm">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12 col-md-12 aboutusnew_ban_btm_txt">
                      {
                        cloudMigrationDataset?.heading &&
                        <h2>
                          {cloudMigrationDataset?.heading}
                        </h2>
                      }
                      <p>
                        {cloudMigrationDataset?.longDescription?.map((description, index) => (
                          <React.Fragment key={index}>
                            {<p>{description?.children[0]?.text}</p>}
                          </React.Fragment>
                        ))}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>}
          {cloudMigrationDataset?.comprehensiveApproach?.length > 0 &&
            <section className="dataquality_section comprehensive_approach_section">
              <div className="container">
                {cloudMigrationDataset?.comprehensiveApproach.map((dataset, dataIndex) => (
                  <div key={dataIndex} className="row">
                    <div className="col-lg-5 col-md-12 col-sm-12">
                      <h2>
                        {dataset?.heading}
                      </h2>
                    </div>
                    <div className="col-lg-7 col-md-12 col-sm-12">
                      <img className="img-fluid" src={dataset?.comprehensiveApproachImg?.url} alt="comprehensiveApproachImg" />
                    </div>
                  </div>
                ))}
              </div>
            </section>}
          {id === 'cloud-migration' ? (
            <section className="achieve_competitive_section reasons_consider_section migration_reason">
              <div className="container">
                {cloudMigrationDataset?.reasonsToConsiderVerticalSection?.map((dataset, dataIndex) => (
                  <div key={dataIndex}>
                    <h2>{dataset?.heading}</h2>
                    <div className="row">
                      {dataset?.reasonsToConsiderCloudAdoptionSection?.map((item, index) => (
                        <div key={index} className="col-lg-4 col-md-4 col-sm-12 achive_sub_main">
                          <div className="achive_sub_div">
                            <img className="img-fluid" src={item?.sectionImg?.url} alt="sectionImg" /> <h1 className="achive_head">{item?.title}</h1>
                            <div className="achive_bodydiv">
                              {item?.longDescription?.map((description, index) => (
                                <React.Fragment key={index}>
                                  {description.type === 'ul' && (
                                    <ul>
                                      {description.children.map((item, itemIndex) => (
                                        <li key={itemIndex}>{item.children[0].text}</li>
                                      ))}
                                    </ul>
                                  )}
                                </React.Fragment>
                              ))}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </section>
          ) : (
            <section className="achieve_competitive_section what_it_does_section">
              <div className="container">
                {cloudMigrationDataset?.reasonsToConsiderVerticalSection?.map((dataset, dataIndex) => {
                  let sectionClassName = '';
                  sectionClassName = id === 'managed-services' || id === 'real-time-analytics'
                    ? "col-lg-4 col-md-4 col-sm-12 achive_sub_main"
                    : "col-lg-3 col-md-3 col-sm-12 achive_sub_main";
                  return (
                    <div key={dataIndex}>
                      <h2>{dataset?.heading}</h2>
                      <div className="row">
                        {dataset?.reasonsToConsiderCloudAdoptionSection?.map((item, index) => (
                          <div key={index} className={sectionClassName}>
                            <div className="achive_sub_div">
                              <img className="img-fluid" src={item?.sectionImg?.url} alt="sectionImg" />
                              <div className="achive_bodydiv">
                                <h1>{item?.title}</h1>
                                {item?.longDescription?.map((description, index) => (
                                  <React.Fragment key={index}>
                                    {description.type === 'ul' ? (
                                      <ul>
                                        {description.children.map((item, itemIndex) => (
                                          <li key={itemIndex}>{item.children[0].text}</li>
                                        ))}
                                      </ul>) : (
                                      <p>
                                        {description.children.map((item, itemIndex) => (
                                          <p key={itemIndex}>{item.text}</p>
                                        ))}
                                      </p>
                                    )}
                                  </React.Fragment>
                                ))}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )
                })}
              </div>
            </section>)}
          {cloudMigrationDataset?.unlockTheBenefits?.length > 0 &&
            <section className="new_cloud_servies_bg data_lake_capabilities_section unlock_benifits_section">
              <div className="new_services_ban_btm_div">
                <div className="container">
                  {cloudMigrationDataset?.unlockTheBenefits?.map((dataset, dataIndex) => (
                    <div key={dataIndex} className="row">
                      <div className="col-lg-6 col-md-12 col-sm-12">
                        <img
                          className="img-fluid whydata_lft_img"
                          src={dataset?.mainSectionImage?.url}
                          alt="CloudMigrationImage"
                        />
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12">
                        <div className="product_eng_txt_main">
                          <h1>{dataset?.heading}</h1>
                        </div>
                        <div className="">
                          {
                            dataset?.subSection?.map((item, index) => {
                              let sectionClassName = '';
                              sectionClassName = id === 'managed-services'
                                ? "whydata_txt_div col-lg-12 col-md-12 col-sm-12"
                                : "whydata_txt_div col-lg-10 col-md-10 col-sm-10";
                              return (
                                <div key={index} className="whydata_rht_main">
                                  {
                                    item?.sectionImg?.url &&
                                    <div className="whydata_img_div col-lg-2 col-md-2 col-sm-2">
                                      <img
                                        className="img-fluid"
                                        src={item?.sectionImg?.url}
                                        alt="sectionImg"
                                      />
                                    </div>
                                  }
                                  <div className={sectionClassName}>
                                    <h2>{item?.title}</h2>
                                    <p>
                                      {item?.shortDescription}
                                    </p>
                                  </div>
                                </div>
                              )
                            })
                          }
                        </div>
                      </div>
                    </div>
                  ))
                  }
                </div>
              </div>
            </section>}
          {id === 'cloud-migration' ? cloudMigrationDataset?.howCanWehelp?.length > 0 && <section className="cloud_mig_how_can_help_section">
            <div className="container">
              {cloudMigrationDataset?.howCanWehelp?.map((dataset, dataIndex) => (
                <div key={dataIndex} className="row">
                  <h1>{dataset?.heading}</h1>
                  <p>
                    {dataset?.shortDescription}
                  </p>
                </div>
              ))}
            </div>
          </section> : cloudMigrationDataset?.howCanWehelp?.length > 0 &&
          <section className="new_cloud_servies_bg data_lake_capabilities_section unlock_benifits_section devops_howcan_help">
            <div className="new_services_ban_btm_div">
              <div className="container">
                {
                  cloudMigrationDataset?.howCanWehelp?.map((dataset, dataIndex) => (
                    <div key={dataIndex} className="row">
                      <div className="col-lg-6 col-md-12 col-sm-12">
                        <img
                          className="img-fluid whydata_lft_img"
                          src={dataset?.howWeCanImg?.url}
                          alt="howCanWeHelpImg"
                        />
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12">
                        <div className="product_eng_txt_main">
                          <h1>{dataset?.heading}</h1>
                          <p>
                            {dataset?.shortDescription}
                          </p>
                        </div>
                        <div className="">
                          {dataset?.howCanWehelpSection?.map((item, index) => (
                            <div key={index} className="whydata_rht_main">
                              <div className="whydata_img_div col-lg-2 col-md-2 col-sm-2">
                                <img
                                  className="img-fluid"
                                  src={item?.sectionImg?.url}
                                  alt="sectionImg"
                                />
                              </div>
                              <div className="whydata_txt_div col-lg-10 col-md-10 col-sm-10">
                                <h2>{item?.title}</h2>
                                <p>
                                  {item?.shortDescription}
                                </p>
                              </div>
                            </div>
                          ))
                          }
                        </div>
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>
          </section>}
          {cloudMigrationDataset?.assessmentAndPlanning?.length > 0 &&
            <section className="new_services_ban_btm_section new_cloud_sec mar_btm_80 assesment_section flex_direction_reverse">
              <div className="new_services_ban_btm_div">
                <div className="container">
                  {cloudMigrationDataset?.assessmentAndPlanning.map((dataset, dataIndex) => (
                    <div key={dataIndex} className="row">
                      <div className="col-lg-6 col-md-12 col-sm-12">
                        <div className="product_eng_txt_main">
                          <h1>{dataset?.heading}</h1>
                          <p>
                            {dataset?.shortDescription}
                          </p>
                          {dataset?.longDescription?.map((description, index) => (
                            <React.Fragment key={index}>
                              {description.type === 'ul' && (
                                <ul>
                                  {description.children.map((item, itemIndex) => (
                                    <li key={itemIndex}>{item.children[0].text}</li>
                                  ))}
                                </ul>
                              )}
                            </React.Fragment>
                          ))}
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12">
                        <img
                          className="img-fluid whydata_lft_img"
                          src={dataset?.assessmentImg?.url}
                          alt="assessmentImg"
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>}
          {cloudMigrationDataset?.optimalCloudService?.length > 0 &&
            <section className="new_cloud_servies_bg data_lake_capabilities_section unlock_benifits_section choosing_sec">
              <div className="new_services_ban_btm_div">
                <div className="container">
                  {cloudMigrationDataset?.optimalCloudService?.map((dataset, dataIndex) => (
                    <div key={dataIndex} className="row">
                      <div className="col-lg-6 col-md-12 col-sm-12">
                        <img
                          className="img-fluid whydata_lft_img"
                          src={dataset?.optimalCloudServiceImg?.url}
                          alt="optimalCloudServiceImg"
                        />
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12">
                        <div className="product_eng_txt_main">
                          <h1>{dataset?.heading}</h1>
                          <p>
                            {dataset?.longDescription}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>}
          {cloudMigrationDataset?.optimalCloudStrategy?.length > 0 &&
            <section className="new_services_ban_btm_section new_cloud_sec mar_btm_80 assesment_section flex_direction_reverse">
              <div className="new_services_ban_btm_div">
                <div className="container">
                  {cloudMigrationDataset?.optimalCloudStrategy.map((dataset, dataIndex) => (
                    <div key={dataIndex} className="row">
                      <div className="col-lg-6 col-md-12 col-sm-12">
                        <div className="product_eng_txt_main">
                          <h1>{dataset?.heading}</h1>
                          <p>
                            {dataset?.longDescription}
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12">
                        <img
                          className="img-fluid whydata_lft_img"
                          src={dataset?.optimalCloudStrategyImg?.url}
                          alt="optimalCloudStrategyImg"
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>}
          {cloudMigrationDataset?.buildingPoC?.length > 0 && (
            <section className="new_cloud_servies_bg data_lake_capabilities_section unlock_benifits_section choosing_sec building_poc">
              <div className="new_services_ban_btm_div">
                <div className="container">
                  {cloudMigrationDataset?.buildingPoC?.map((dataset, dataIndex) => (
                    <div key={dataIndex} className="row">
                      <div className="col-lg-6 col-md-12 col-sm-12">
                        <img
                          className="img-fluid whydata_lft_img"
                          src={dataset?.buildingPoCImg?.url}
                          alt="buildingPoCImg"
                        />
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12">
                        <div className="product_eng_txt_main">
                          <h1>{dataset?.heading}</h1>
                          <p>
                            {dataset?.shortDescription}
                          </p>
                          {dataset?.longDescription?.map((description, index) => (
                            <React.Fragment key={index}>
                              {description.type === 'ul' && (
                                <ul>
                                  {description.children.map((item, itemIndex) => (
                                    <li key={itemIndex}>{item.children[0].text}</li>
                                  ))}
                                </ul>
                              )}
                            </React.Fragment>
                          ))}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>)}
          {cloudMigrationDataset?.ourExpertiseInBuilding?.length > 0 &&
            <section className="dataquality_section cloud_real_expertise_building">
              <div className="container">
                {cloudMigrationDataset?.ourExpertiseInBuilding?.map((item, index) => (
                  <div key={index} className="row">
                    <div className="col-lg-5 col-md-12 col-sm-12">
                      <h2>{item?.heading}</h2>
                      <p>
                        {item?.longDescription}
                      </p>
                    </div>
                    <div className="col-lg-7 col-md-12 col-sm-12">
                      <img
                        className="img-fluid"
                        src={item?.ourExpertiseInBuildingImage?.url}
                        alt="ourExpertiseInBuildingImage"
                      />
                    </div>
                  </div>
                ))}
              </div>
            </section>}
          {cloudMigrationDataset && cloudMigrationDataset?.technologyStackWeUse.length > 0 &&
            <section className="devoops_tech data-preparation_tech">
              <TechnologyStack dataset={cloudMigrationDataset && cloudMigrationDataset} />
            </section>}
          {id === 'managed-services' ?
            <section className="competence_section">
              {cloudMigrationDataset?.partnerships?.map((dataset, dataIndex) => (
                <div key={dataIndex} className="container">
                  <h1>{dataset?.heading}</h1>
                  <div className="row">
                    <div className="col-lg-3 ol-md-3 col-sm-12">
                      <img className="img-fluid" src={dataset?.ourAWSCompetenceAndPartnershipsImg?.url} alt="ourAWSCompetenceImg" />
                    </div>
                    {
                      dataset?.aWSCompetenceSection?.map((item, index) => (
                        <div key={index} className="col-lg-3 ol-md-3 col-sm-12">
                          <h2>{item?.totalCount}</h2>
                          <p>{item?.heading}</p>
                        </div>
                      ))
                    }
                  </div>
                </div>
              ))}
            </section> :
            cloudMigrationDataset?.partnerships?.length > 0 &&
            <section className="migration_partnerships_section">
              <div className="container">
                <h1>{cloudMigrationDataset?.partnerships[0]?.heading}</h1>
                <div className="row">
                  {
                    cloudMigrationDataset?.partnerships?.map((dataset, dataIndex) => (
                      <div key={dataIndex} className="col-lg-6 col-md-6 col-sm-12">
                        <img
                          className="img-fluid whydata_lft_img"
                          src={dataset?.ourAWSCompetenceAndPartnershipsImg?.url}
                          alt="partnershipsImg"
                        />
                      </div>
                    ))
                  }
                </div>
              </div>
            </section>}
            <ConversationSection />
        </div>
      }
      {scrollActive && (
        <div className="slide_up_arrow_sec">
          <button onClick={handleLoadMore}>
            {" "}
            <img
              className="img-fluid"
              src={getUpArrowSrc}
              alt="up_slide_arrow"
            ></img>
          </button>
        </div>
      )}
    </div>
  );
};

export default MainCloudEnablement;
