import React, { useEffect, useState, useRef } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";
import { ServerURL } from "../../urls";
import { Spinner } from "react-bootstrap";
import TechnologyStack from "./TechnolgyStack";
import { ServiceBanner } from "./Components/DataEngineering/ServiceBanner";
import { getUpArrowSrc } from "../../utils/getUpArrowSrc";
import ConversationSection from "../Contact/ConversationSection";

const paths = [
  'web-applications', 'qa-services', 'enterprise-applications', 'mobility'
];
const MainProductEngineering = () => {
  const { id } = useParams();
  const [productEnterpriseDataset, setProductEnterpriseDataset] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [scrollActive, setScrollActive] = useState(false);
  const sectionRefs = useRef(null);

  const scrollActiveFun = () => {
    if (window.scrollY >= 100) {
      setScrollActive(true);
    } else {
      setScrollActive(false);
    }
  };

  useEffect(() => {
    scrollActiveFun();
    window.addEventListener("scroll", scrollActiveFun);
    return () => {
      window.removeEventListener("scroll", scrollActiveFun);
    };
  }, []);

  const handleLoadMore = () => {
    sectionRefs.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (!paths.includes(id)) {
      navigate("/page-not-found");
    } else {
      const productEnterpriseEngineeringURL = `${ServerURL}/mainProductEngineering/${id}`;
      const fetchData = async () => {
        try {
          const productEnterpriseResponse = await fetch(productEnterpriseEngineeringURL, {
            credentials: "include", // authentication to access APIs
          }
          );
          const productEnterpriseJson = await productEnterpriseResponse.json();
          setProductEnterpriseDataset(productEnterpriseJson);
          setIsLoading(false);
        } catch (error) {
          console.log("error", error);
        }
      };

      fetchData();
    }

  }, [id]);

  return (
    <div>
      {isLoading ? (
        <div className="loader-container">
          <Spinner animation="border" variant="primary" />
        </div>
      ) :
        <div ref={sectionRefs}>
          <Helmet>
            <title>{productEnterpriseDataset?.metaTitle}</title>
            <meta name="description" content={productEnterpriseDataset?.metaDescription} />
          </Helmet>
          <ServiceBanner dataset={productEnterpriseDataset} />
          {productEnterpriseDataset?.longDescription[0]?.children[0]?.text !== '' &&
            <section className="new_services_ban_btm_section aboutnew_ban_btm_txt_sec">
              <div className="aboutnew_ban_btm">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12 col-md-12 aboutusnew_ban_btm_txt">
                      {
                        productEnterpriseDataset?.heading &&
                        <h2>
                          {productEnterpriseDataset?.heading}
                        </h2>
                      }
                      <p>
                        {productEnterpriseDataset?.longDescription?.map((description, index) => (
                          <React.Fragment key={index}>
                            {<p>{description?.children[0]?.text}</p>}
                          </React.Fragment>
                        ))}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          }

          {productEnterpriseDataset?.ourDevelopmentAndTestingPractices && productEnterpriseDataset?.ourDevelopmentAndTestingPractices[0]?.sectionImage &&
            <section className="achieve_competitive_section mobility_enterprice_section">
              <div className="container">
                <div className="row">
                  {
                    productEnterpriseDataset?.ourDevelopmentAndTestingPractices?.map((dataset, dataIndex) => (
                      <div key={dataIndex} className="col-lg-3 col-md-3 col-sm-12 achive_sub_main">
                        <div className="achive_sub_div">
                          <img className="img-fluid" src={dataset?.sectionImage?.url} alt="enterpriseImage" />
                          <div className="achive_bodydiv">
                            <h1>{dataset?.heading}</h1>
                            <p>
                              {dataset?.longDescription}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))
                  }
                </div>
              </div>
            </section>
          }

          {productEnterpriseDataset && productEnterpriseDataset?.enterpriseMobilitySolutions[0]?.enterpriseMobilityImg &&
            <section className="new_cloud_servies_bg enterprice_mobility_section enterprice_mb_bg">
              <div className="new_services_ban_btm_div">
                <div className="container">
                  {
                    productEnterpriseDataset?.enterpriseMobilitySolutions?.map((item, index) => (
                      <div key={index} className="row">
                        <div className="col-lg-6 col-md-12 col-sm-12">
                          <img
                            className="img-fluid"
                            src={item?.enterpriseMobilityImg?.url}
                            alt="enterpriseMobilityImg"
                          />
                        </div>
                        <div className="col-lg-6 col-md-12 ol-sm-12 enterprice_mobility_rht">
                          <h1 className="enterprice_mobility_solutions_title">
                            {item?.heading}
                          </h1>
                          <p>
                            {item?.shortDescription}
                          </p>
                          {
                            item?.enterpriseMobilitySolutionSection?.map((dataset, dataIndex) => (
                              <div key={dataIndex} className="whydata_rht_main">
                                <div className="whydata_img_div col-lg-2 col-md-2 col-sm-2">
                                  <img
                                    className="img-fluid"
                                    src={dataset?.sectionImg?.url}
                                    alt="sectionImg"
                                  />
                                </div>
                                <div className="whydata_txt_div col-lg-10 col-md-10 col-sm-10">
                                  <h2>{dataset?.title}</h2>
                                  <p>
                                    {dataset?.shortDescription}
                                  </p>
                                </div>
                              </div>
                            ))
                          }
                        </div>
                      </div>
                    ))
                  }
                </div>
              </div>
            </section>
          }
          {productEnterpriseDataset?.whyEnterpriseWebApplications && productEnterpriseDataset?.whyEnterpriseWebApplications.length > 0 &&
            <section className="new_whatwedo_Section new_loud_sec">
              <div className="new_whatwedo_sub">
                {
                  productEnterpriseDataset?.whyEnterpriseWebApplications?.map((item, index) => (
                    <div key={index}>
                      <div className="container">
                        <div className="row">
                          <div className="new_whatwe_do_txt">
                            <h2>{item?.title}</h2>
                            {item?.shortDescription?.map((description, index) => (
                              <React.Fragment key={index}>
                                {description?.children?.map((item, itemIndex) => (
                                  <p key={itemIndex}>{item.text}</p>
                                ))}
                              </React.Fragment>
                            ))}
                          </div>
                        </div>
                      </div>
                      {
                        item?.sectionImage &&
                        <div className="">
                          <section className="new_cloud_servies_bg our_enterprie_section">
                            <div className="new_services_ban_btm_div">
                              <div className="container">
                                <div className="row align-items-center">
                                  <div className="col-lg-6 col-md-12 col-sm-12">
                                    <img
                                      className="img-fluid"
                                      src={item?.sectionImage?.url}
                                      alt="enterpriseImage"
                                    />
                                  </div>
                                  <div className="col-lg-6 col-md-12 col-sm-12">
                                    <div className="product_eng_txt_main">
                                      <h1>{item?.heading}</h1>
                                      {item?.longDescription?.map((description, index) => (
                                        <React.Fragment key={index}>
                                          {description.type === 'ul' && (
                                            <ul>
                                              {description.children.map((item, itemIndex) => (
                                                <li key={itemIndex}>{item.children[0].text}</li>
                                              ))}
                                            </ul>
                                          )}
                                          {item?.children?.map((child, childIndex) => (
                                            <React.Fragment key={childIndex}>
                                              {child.bold && <strong>{child.text}</strong>}
                                              {!child.bold && <span>{child.text}</span>}<br />
                                            </React.Fragment>
                                          ))}
                                        </React.Fragment>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                        </div>
                      }
                    </div>
                  ))
                }
              </div>
            </section>
          }
          {
            productEnterpriseDataset?.howCanWehelp && productEnterpriseDataset?.howCanWehelp.length > 0 &&
            <section className="whydata_preparation_section enterprice_howcan_help_section flex_direction_reverse enterprice_mb_bg">
              <div className="container">
                {productEnterpriseDataset?.howCanWehelp?.map((dataset, dataIndex) => (
                  <div key={dataIndex}>
                    <h2 className="whydata_title enterprice_howcan_help">{dataset?.heading}</h2>
                    <div className="row">
                      <div className="col-lg-6 col-md-12 ol-sm-12">
                        {
                          dataset?.howCanWehelpSection?.map((item, index) => (
                            <div key={index} className="whydata_rht_main">
                              <div className="whydata_img_div col-lg-2 col-md-2 col-sm-2">
                                <img className="img-fluid" src={item?.sectionImg?.url} alt="sectionImg" />
                              </div>
                              <div className="whydata_txt_div col-lg-10 col-md-10 col-sm-10">
                                <h2>{item?.title}</h2>
                                <p>
                                  {item.shortDescription}
                                </p>
                              </div>
                            </div>
                          ))
                        }
                      </div>
                      <div className="col-lg-6 col-md-12 ol-sm-12">
                        <img
                          className="img-fluid whydata_lft_img"
                          src={dataset?.howWeCanImg?.url}
                          alt="howWeCanImg"
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </section>
          }
          {
            productEnterpriseDataset?.acceleratingCompetitiveness[0]?.sectionImg &&
            <section className="new_cloud_servies_bg enterprice_accelerating_section">
              <div className="new_services_ban_btm_div">
                <div className="container">
                  {productEnterpriseDataset?.acceleratingCompetitiveness?.map((dataset, index) => (
                    <div key={index} className="row">
                      <div className="col-lg-6 col-md-12 col-sm-12">
                        <img className="img-fluid" src={dataset?.sectionImg?.url} alt="sectionImg" />
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12">
                        <div className="product_eng_txt_main">
                          <h1>{dataset?.heading}</h1>
                          <p>
                            {dataset?.longDescription?.map((description, index) => (
                              <React.Fragment key={index}>
                                {<p>{description?.children[0]?.text}</p>}
                              </React.Fragment>
                            ))}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
          }

          {
            productEnterpriseDataset && productEnterpriseDataset?.technologyStackWeUse.length > 0 &&
            <section className="devoops_tech data-preparation_tech">
              <TechnologyStack dataset={productEnterpriseDataset && productEnterpriseDataset} />
            </section>

          }
          <ConversationSection />
        </div>
      }
      {scrollActive && (
        <div className="slide_up_arrow_sec">
          <button onClick={handleLoadMore}>
            {" "}
            <img
              className="img-fluid"
              src={getUpArrowSrc}
              alt="up_slide_arrow"
            ></img>
          </button>
        </div>
      )}
    </div>
  );
};

export default MainProductEngineering;
