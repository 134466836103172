import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import BlogDetailsRedirect from "./Components/Blogs/BlogDetailsRedirect";

// RedirectUrls component created for redirecting old URLs to the new URLs, as per marketing requirement
// some old URLs are indexed in Google Search Console, and it shows 'page not found' for these old URLs.
// Therefore, this RedirectUrls component redirects these indexed old URLs to new URLs.

const RedirectUrls = () => {
  return (
    <Routes>
      <Route path="/about" element={<Navigate to="/aboutus/" />} />
      <Route
        path="/page-builder"
        element={<Navigate to="/innovation/page-builder/" />}
      />
      <Route
        path="/v-assist"
        element={<Navigate to="/innovation/v-assist/" />}
      />
      <Route path="/contact" element={<Navigate to="/contactus/" />} />
      <Route path="/blog/:id" element={<BlogDetailsRedirect />} />
      <Route path="/blogs/:id" element={<BlogDetailsRedirect />} />
      <Route
        path="/success-stories/norse"
        element={<Navigate to="/success-stories/health-analytics/" />}
      />
      <Route
        path="/success-stories/quisk"
        element={<Navigate to="/success-stories/mobile-payments/" />}
      />
      <Route
        path="/success-stories/enthinnai"
        element={<Navigate to="/success-stories/communication-platform/" />}
      />
      <Route
        path="/success-stories/edvie"
        element={<Navigate to="/success-stories/learning-platform/" />}
      />
      <Route
        path="/success-stories/verge"
        element={<Navigate to="/success-stories/online-ordering-platform/" />}
      />
      <Route
        path="/success-stories/iot-based-data"
        element={<Navigate to="/success-stories/iot-data-solutions/" />}
      />
      <Route
        path="/success-stories/cwc-story"
        element={<Navigate to="/success-stories/experiential-learning/" />}
      />
      <Route
        path="/blog/reusable-github-flow-for-efficient-devps"
        element={
          <Navigate to="/blogs/reusable-github-flow-for-efficient-devops/" />
        }
      />
      <Route
        path="/api-testing-using-postman/"
        element={<Navigate to="/blogs/api-testing-using-postman/" />}
      />
      <Route
        path="/services/product-engineering/product-eng-mobility/"
        element={<Navigate to="/services/product-engineering/mobility/" />}
      />
      <Route
        path="/services/cloud-enablement/devopson-cloud/"
        element={<Navigate to="/services/cloud-enablement/devops-on-cloud/" />}
      />
      <Route
        path="/blogs/the_path_of_product_modernization/"
        element={<Navigate to="/blogs/elevating-software-excellence-the-path-of-product-modernization/" />}
      />
      <Route
        path="/blogs/a_comprehensive_guide_to_protect_mobile_data/"
        element={<Navigate to="/blogs/a-comprehensive-guide-to-protect-mobile-data/" />}
      />
    </Routes>
  );
};

export default RedirectUrls;
