import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Spinner } from "react-bootstrap";
import { ServerURL } from "../../urls";
import { ServiceBanner } from "./Components/DataEngineering/ServiceBanner";
import { getUpArrowSrc } from "../../utils/getUpArrowSrc";
import ConversationSection from "../Contact/ConversationSection";

const paths = [
  "model-development-and-deployment",
  "generative-ai",
  "ai-automation",
];
const MainAIMLSolutions = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [dataEngineeringDataset, setDataEngineeringDataset] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [scrollActive, setScrollActive] = useState(false);
  const sectionRefs = useRef(null);

  const scrollActiveFun = () => {
    if (window.scrollY >= 100) {
      setScrollActive(true);
    } else {
      setScrollActive(false);
    }
  };

  useEffect(() => {
    scrollActiveFun();
    window.addEventListener("scroll", scrollActiveFun);
    return () => {
      window.removeEventListener("scroll", scrollActiveFun);
    };
  }, []);

  const handleLoadMore = () => {
    sectionRefs.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (!paths.includes(id)) {
      navigate("/page-not-found");
    } else {
      const dataEngineeringServiceURL = `${ServerURL}/mainAimlSolutions/${id}`;
      const fetchData = async () => {
        try {
          const dataEngineeringServiceResponse = await fetch(
            dataEngineeringServiceURL,
            {
              credentials: "include",
            }
          );
          const dataEngineeringServiceJSON =
            await dataEngineeringServiceResponse.json();
          setDataEngineeringDataset(dataEngineeringServiceJSON);
          setIsLoading(false);
        } catch (error) {
          console.log("error", error);
        }
      };

      fetchData();
    }
  }, [id]);

  return (
    <div>
      {isLoading ? (
        <div className="loader-container">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <div ref={sectionRefs}>
          <Helmet>
            <title>{dataEngineeringDataset?.metaTitle}</title>
            <meta
              name="description"
              content={dataEngineeringDataset?.metaDescription}
            />
          </Helmet>
          <ServiceBanner dataset={dataEngineeringDataset} />
          {dataEngineeringDataset?.longDescription && (
            <section className="new_services_ban_btm_section aboutnew_ban_btm_txt_sec">
              <div className="aboutnew_ban_btm">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12 col-md-12 aboutusnew_ban_btm_txt">
                      {dataEngineeringDataset?.heading && (
                        <h2>{dataEngineeringDataset?.heading}</h2>
                      )}
                      <p>
                        {dataEngineeringDataset?.longDescription?.map(
                          (description, index) => (
                            <React.Fragment key={index}>
                              {<p>{description?.children[0]?.text}</p>}
                            </React.Fragment>
                          )
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}
          {dataEngineeringDataset?.modelSection?.length > 0 && (
            <section className="whydata_preparation_section">
              <div>
                {dataEngineeringDataset?.modelSection?.map((item, index) => {
                  return (
                    <div key={index} className="container">
                      <h2 className="whydata_title whydata_prepare_title">
                        {item.heading}
                      </h2>
                      <div className="row align_items_center">
                        <div className="col-lg-6 col-md-12 col-sm-12">
                          <img
                            className="img-fluid whydata_lft_img"
                            src={item.modelSectionImage.url}
                            alt="modelSectionImage"
                          />
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12">
                          {item?.modelSubSection?.map((dataset, index) => (
                            <div key={index} className="whydata_rht_main">
                              <div className="whydata_img_div col-lg-2 col-md-2 col-sm-2">
                                <img
                                  className="img-fluid"
                                  src={dataset?.sectionImg?.url}
                                  alt="sectionImg"
                                />
                              </div>
                              <div className="whydata_txt_div col-lg-10 col-md-10 col-sm-10">
                                <h2>{dataset.title}</h2>
                                <p>{dataset?.shortDescription}</p>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </section>
          )}

          {dataEngineeringDataset?.ourProvenSection?.length > 0 && (
            <section className="achieve_competitive_section mb-0">
              <div>
                {dataEngineeringDataset?.ourProvenSection?.map(
                  (item, index) => (
                    <div key={index} className="container">
                      <h2>{item?.heading}</h2>
                      <div className="row">
                        {item?.ourProvenSubSection?.map((dataset, index) => (
                          <div
                            key={index}
                            className={`${
                              id === "ai-automation"
                                ? "col-lg-4 col-md-4 col-sm-12 achive_sub_main"
                                : "col-lg-6 col-md-6 col-sm-12 achive_sub_main"
                            }`}
                          >
                            <div className="achive_sub_div">
                              <img
                                className="img-fluid"
                                src={dataset?.sectionImg?.url}
                                alt="sectionImg"
                              />
                              <div className="achive_bodydiv">
                                <h1>{dataset?.title}</h1>
                                <p>{dataset?.shortDescription}</p>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )
                )}
              </div>
            </section>
          )}

          <section
            class="ai_ml_tech_section"
            style={{
              backgroundImage:
                "url(" + dataEngineeringDataset?.subBannerImage?.url + ")",
            }}
          >
            <div class="container">
              <div class="row">
                <div class="col-lg-12">
                  {id === "model-development-and-deployment" && (
                    <h4>
                      Our developers are proficient with cloud providers such as{" "}
                      <span>AWS, Google Cloud, and Azure</span> and are
                      experienced with popular ML frameworks like{" "}
                      <span>Tensorflow, PyTorch and Scikit-learn.</span> We have
                      also partnered with <span>Databricks and Snowflake</span>{" "}
                      to enhance our engagement.
                    </h4>
                  )}
                  {id === "generative-ai" && (
                    <h4>
                      Our developers possess expertise in generative AI
                      technologies, having experience with{" "}
                      <span>
                        OpenAI (ChatGPT), Mistral, HuggingFace, Langchain,
                        LlamaIndex, and Anthropic APIs.{" "}
                      </span>
                      They leverage this knowledge to integrate generative AI
                      solutions for our clients' projects.
                    </h4>
                  )}
                  {id === "ai-automation" && (
                    <h4>
                      We utilize cutting-edge technologies, including AI & ML
                      frameworks like{" "}
                      <span>
                        HuggingFace, Langchain, LlamaIndex, TensorFlow, and
                        PyTorch,
                      </span>{" "}
                      and <span>robotic automation tools{" "}</span>such as{" "}
                      <span>ROS (Robot Operating System).</span> Our team is
                      experienced with cloud platforms like{" "}
                      <span>AWS, Google Cloud, </span> and <span> Azure </span> to
                      ensure scalable and efficient deployment.
                    </h4>
                  )}
                </div>
              </div>
            </div>
          </section>
          <ConversationSection />
        </div>
      )}
      {scrollActive && (
        <div className="slide_up_arrow_sec">
          <button onClick={handleLoadMore}>
            {" "}
            <img
              className="img-fluid"
              src={getUpArrowSrc}
              alt="up_slide_arrow"
            ></img>
          </button>
        </div>
      )}
    </div>
  );
};

export default MainAIMLSolutions;
