import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Spinner } from "react-bootstrap";
import { ServerURL } from "../../urls";
import TechnologyStack from "./TechnolgyStack";
import { AchieveCometitiveSection } from "./Components/DataEngineering/AchieveCometitiveSection";
import { WhyDataPreprationDataEngineering } from "./Components/DataEngineering/WhyDataPreprationDataEngineering";
import { ServiceBanner } from "./Components/DataEngineering/ServiceBanner";
import { getUpArrowSrc } from "../../utils/getUpArrowSrc";
import ConversationSection from "../Contact/ConversationSection";

const paths = [
  'realtime-analytics', 'data-preparation', 'bigdata-solutions'
];
const MainDataEngineering = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [dataEngineeringDataset, setDataEngineeringDataset] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [scrollActive, setScrollActive] = useState(false);
  const sectionRefs = useRef(null);

  const scrollActiveFun = () => {
    if (window.scrollY >= 100) {
      setScrollActive(true);
    } else {
      setScrollActive(false);
    }
  };

  useEffect(() => {
    scrollActiveFun();
    window.addEventListener("scroll", scrollActiveFun);
    return () => {
      window.removeEventListener("scroll", scrollActiveFun);
    };
  }, []);

  const handleLoadMore = () => {
    sectionRefs.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (!paths.includes(id)) {
      navigate("/page-not-found");
    } else {
      const dataEngineeringServiceURL = `${ServerURL}/mainDataEngineering/${id}`;
      const fetchData = async () => {
        try {
          const dataEngineeringServiceResponse = await fetch(
            dataEngineeringServiceURL,
            {
              credentials: "include", // authentication to access APIs
            }
          );
          const dataEngineeringServiceJSON =
            await dataEngineeringServiceResponse.json();
          setDataEngineeringDataset(dataEngineeringServiceJSON);
          setIsLoading(false);
        } catch (error) {
          console.log("error", error);
        }
      };

      fetchData();
    }

  }, [id]);

  return (
    <div>
      {isLoading ? (
        <div className="loader-container">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <div ref={sectionRefs}>
          <Helmet>
            <title>{dataEngineeringDataset?.metaTitle}</title>
            <meta
              name="description"
              content={dataEngineeringDataset?.metaDescription}
            />
          </Helmet>
          <ServiceBanner dataset={dataEngineeringDataset} />
          {dataEngineeringDataset?.longDescription && (
            <section className="new_services_ban_btm_section aboutnew_ban_btm_txt_sec">
              <div className="aboutnew_ban_btm">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12 col-md-12 aboutusnew_ban_btm_txt">
                      {dataEngineeringDataset?.heading && (
                        <h2>{dataEngineeringDataset?.heading}</h2>
                      )}
                      <p>
                        {dataEngineeringDataset?.longDescription?.map(
                          (description, index) => (
                            <React.Fragment key={index}>
                              {<p>{description?.children[0]?.text}</p>}
                            </React.Fragment>
                          )
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}
          {dataEngineeringDataset?.whyDataPreparation?.length > 0 && (
            <section className="whydata_preparation_section">
              <WhyDataPreprationDataEngineering
                dataset={dataEngineeringDataset?.whyDataPreparation}
              />
            </section>
          )}
          {dataEngineeringDataset?.enlumeEmpowersBusinesses?.length > 0 && (
            <section className="new_services_ban_btm_section new_cloud_sec mar_btm_80 flex_direction_reverse">
              <div className="new_services_ban_btm_div">
                <div className="container">
                  {dataEngineeringDataset?.enlumeEmpowersBusinesses?.map(
                    (item, index) => (
                      <div key={index} className="row">
                        <div className="col-lg-6 col-md-12 col-sm-12">
                          <div className="product_eng_txt_main">
                            {item.longDescription?.map((description, index) => (
                              <React.Fragment key={index}>
                                {<p>{description?.children[0]?.text}</p>}
                              </React.Fragment>
                            ))}
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12">
                          <img
                            className="img-fluid whydata_lft_img"
                            src={item?.enlumeEmpowersBusinessesImage?.url}
                            alt="whydata_lft_img"
                          />
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
            </section>
          )}
          {dataEngineeringDataset?.bigDataServices?.url && (
            <section className="bigdata_services_section">
              <div className="container">
                <h2>Big data services</h2>
                <div className="row">
                  <div className="bigdata_services_img_div">
                    <img
                      className="img-fluid whydata_lft_img"
                      src={dataEngineeringDataset?.bigDataServices?.url}
                      alt="bigdata_services_img"
                    />
                  </div>
                </div>
              </div>
            </section>
          )}
          {dataEngineeringDataset?.powerOfEnLume?.length > 0 && (
            <section className="achieve_competitive_section leveringthe_power_section leveraging_section">
              <div className="container">
                {dataEngineeringDataset?.powerOfEnLume.map(
                  (dataset, dataIndex) => (
                    <div key={dataIndex}>
                      <h2>{dataset?.heading}</h2>
                      <div className="row">
                        {dataset?.powerOfEnLumeSection?.map((item, index) => (
                          <div
                            key={index}
                            className="col-lg-4 col-md-4 col-sm-12 achive_sub_main"
                          >
                            <div className="achive_sub_div">
                              <img
                                className="img-fluid"
                                src={item?.sectionImg?.url}
                                alt="bi_data_icon"
                              />
                              <div className="achive_bodydiv">
                                <h1>{item?.title}</h1>
                                <p>{item?.shortDescription}</p>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )
                )}
              </div>
            </section>
          )}
          {dataEngineeringDataset?.whyAWSAndEnLume?.length > 0 && (
            <section className="whydata_preparation_section">
              <div className="container">
                {dataEngineeringDataset?.whyAWSAndEnLume?.map(
                  (dataset, dataIndex) => (
                    <div key={dataIndex}>
                      <h2 className="whydata_title why_aws_title">
                        {dataset?.heading}
                      </h2>
                      <div className="row">
                        <div className="col-lg-6 col-md-12 ol-sm-12">
                          <img
                            className="img-fluid whydata_lft_img"
                            src={dataset?.whyAWSAndEnLumeImage?.url}
                            alt="whydata_lft_img"
                          />
                        </div>
                        <div className="col-lg-6 col-md-12 ol-sm-12">
                          {dataset?.whyAWSAndEnLumeSection?.map(
                            (item, index) => (
                              <div key={index} className="whydata_rht_main">
                                <div className="whydata_img_div col-lg-2 col-md-2 col-sm-2">
                                  <img
                                    className="img-fluid"
                                    src={item?.sectionImg?.url}
                                    alt="whydata_lft_img"
                                  />
                                </div>
                                <div className="whydata_txt_div col-lg-10 col-md-10 col-sm-10">
                                  <h2>{item?.title}</h2>
                                  <p>{item?.shortDescription}</p>
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>
            </section>
          )}
          {dataEngineeringDataset?.powerOfCloudForDataLakes?.length > 0 && (
            <section className="new_services_ban_btm_section new_cloud_sec mar_btm_80 flex_direction_reverse">
              <div className="new_services_ban_btm_div">
                <div className="container">
                  {dataEngineeringDataset?.powerOfCloudForDataLakes?.map(
                    (dataset, dataIndex) => (
                      <div key={dataIndex} className="row">
                        <div className="col-lg-6 col-md-12 col-sm-12">
                          <div className="product_eng_txt_main">
                            <h1>{dataset?.heading}</h1>
                            <p>{dataset?.longDescription}</p>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12">
                          <img
                            className="img-fluid whydata_lft_img"
                            src={dataset?.sectionImg?.url}
                            alt="whydata_lft_img"
                          />
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
            </section>
          )}
          {dataEngineeringDataset?.AWSAndEnLumeDataLakeCapabilities?.length >
            0 && (
              <section className="new_cloud_servies_bg data_lake_capabilities_section">
                <div className="new_services_ban_btm_div">
                  <div className="container">
                    {dataEngineeringDataset?.AWSAndEnLumeDataLakeCapabilities?.map(
                      (dataset, dataIndex) => (
                        <div key={dataIndex} className="row">
                          <div className="col-lg-6 col-md-12 col-sm-12">
                            <img
                              className="img-fluid whydata_lft_img"
                              src={dataset?.sectionImg?.url}
                              alt="whydata_lft_img"
                            />
                          </div>
                          <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="product_eng_txt_main">
                              <h1>{dataset?.heading}</h1>
                              <div className="expertise_sub row">
                                <div className="expertise_lft col-lg-12 col-md-12 ">
                                  {dataset?.longDescription?.map(
                                    (description, index) => (
                                      <React.Fragment key={index}>
                                        {description.type === "ul" && (
                                          <ul className="data_capability_ul">
                                            {description.children.map(
                                              (item, itemIndex) => (
                                                <li key={itemIndex}>
                                                  {item.children[0].text}
                                                </li>
                                              )
                                            )}
                                          </ul>
                                        )}
                                      </React.Fragment>
                                    )
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </section>
            )}
          {dataEngineeringDataset?.healthcareCostManagement?.length > 0 && (
            <div>
              <section className="project_highlets_section">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12 col-md-12">
                      <h1>Project highlights</h1>
                    </div>
                  </div>
                </div>
              </section>

              <section className="new_services_ban_btm_section new_cloud_sec mar_btm_80 project_highlets_body_section flex_direction_reverse">
                <div className="new_services_ban_btm_div">
                  <div className="container">
                    {dataEngineeringDataset?.healthcareCostManagement?.map(
                      (dataset, dataIndex) => (
                        <div key={dataIndex} className="row">
                          <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="product_eng_txt_main">
                              <h1>{dataset.heading}</h1>
                              {dataset.longDescription?.map(
                                (description, index) => (
                                  <React.Fragment key={index}>
                                    {<p>{description?.children[0]?.text}</p>}
                                  </React.Fragment>
                                )
                              )}
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-12 col-sm-12">
                            <img
                              className="img-fluid whydata_lft_img"
                              src={dataset?.sectionImg?.url}
                              alt="sectionimg"
                            />
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </section>
              <section className="liquor_section">
                <div className="container">
                  {dataEngineeringDataset?.liquorIndustryInventorySolution?.map(
                    (dataset, dataIndex) => (
                      <div key={dataIndex} className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <img
                            className="img-fluid whydata_lft_img"
                            src={dataset?.sectionImg?.url}
                            alt="whydata_lft_img"
                          />
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <h2>{dataset?.heading}</h2>
                          {dataset.longDescription?.map(
                            (description, index) => (
                              <React.Fragment key={index}>
                                {<p>{description?.children[0]?.text}</p>}
                              </React.Fragment>
                            )
                          )}
                        </div>
                      </div>
                    )
                  )}
                </div>
              </section>
            </div>
          )}
          {dataEngineeringDataset?.activeCompetitiveAdvantage?.length > 0 && (
            <section className="achieve_competitive_section">
              <AchieveCometitiveSection
                dataset={dataEngineeringDataset?.activeCompetitiveAdvantage}
              />
            </section>
          )}
          {dataEngineeringDataset?.realTimeAnalyticsSolutions?.length > 0 && (
            <section className="dataquality_section realtime_expert_building">
              <div className="container">
                {dataEngineeringDataset?.realTimeAnalyticsSolutions?.map(
                  (dataset, dataIndex) => (
                    <div key={dataIndex} className="row">
                      <div className="col-lg-6 col-md-12 col-sm-12">
                        <h2>{dataset?.heading}</h2>
                        <p>
                          {
                            dataset?.realTimeAnalyticsSolutionsSection[0]
                              .longDescription
                          }
                        </p>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12">
                        <img
                          className="img-fluid"
                          src={
                            dataset?.realTimeAnalyticsSolutionsSection[0]
                              .sectionImg?.url
                          }
                          alt="sectionImg"
                        />
                      </div>
                    </div>
                  )
                )}
              </div>
            </section>
          )}

          {dataEngineeringDataset?.dataQualityDimensions?.length > 0 && (
            <section className="dataquality_section">
              <div className="container">
                {dataEngineeringDataset?.dataQualityDimensions?.map(
                  (item, index) =>
                    item.dataQualityDimensionsSection?.map((dataset) => (
                      <div key={index} className="row">
                        <div className="col-lg-6 col-md-12 col-sm-12">
                          <h2>{item.heading}</h2>
                          {dataset?.longDescription?.map(
                            (description, index) => (
                              <React.Fragment key={index}>
                                {description?.children?.map(
                                  (child, childIndex) => (
                                    <React.Fragment key={childIndex}>
                                      {child.bold && (
                                        <strong>{child.text} </strong>
                                      )}
                                      {!child.bold && <span>{child.text}</span>}
                                      <br />
                                    </React.Fragment>
                                  )
                                )}
                              </React.Fragment>
                            )
                          )}
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12">
                          <img
                            className="img-fluid"
                            src={dataset?.sectionImg.url}
                            alt="sectionImg"
                          />
                        </div>
                      </div>
                    ))
                )}
              </div>
            </section>
          )}
          {dataEngineeringDataset?.ourCompetitiveEdge?.length > 0 && (
            <div>
              <section className="core_values_section our_competitive_edge_section mar_btm50">
                <div className="core_values_div">
                  <div className="container">
                    <div className="row">
                      <h1 className="competittive_edge_title">
                        Our competitive edge
                      </h1>
                    </div>
                    <div className="row">
                      {dataEngineeringDataset?.ourCompetitiveEdge?.map((item) =>
                        item.ourCompetitiveEdgeSection?.map(
                          (dataset, dataIndex) => (
                            <div
                              key={dataIndex}
                              className="col-lg-6 col-md-6 col-sm-12 core_values_sub_main"
                            >
                              <div className="core_values_sub">
                                <h2>{dataset.title}</h2>
                                <p>{dataset.longDescription}</p>
                              </div>
                            </div>
                          )
                        )
                      )}
                    </div>
                  </div>
                </div>
              </section>
            </div>
          )}
          {dataEngineeringDataset?.howWeSolveItForYou?.map(
            (dataset, dataIndex) => {
              return (
                <section key={dataIndex} className="howwe_solvenew_section">
                  <div className="container">
                    <div>
                      <h2>{dataset?.heading}</h2>
                      <div className="row">
                        {dataset?.howWeSolveItForYouSection?.map(
                          (item, index) => {
                            return (
                              <div
                                key={index}
                                className="col-lg-4 col-md-4 col-sm-12 howwe_solvenew_sub_main"
                              >
                                <div className="howwe_solvenew_section_sub">
                                  <img
                                    className="img-fluid"
                                    src={item?.sectionImg?.url}
                                    alt="bi_data_icon"
                                  />
                                  <div className="howwe_solvenew_section_body">
                                    <h3>{item?.title}</h3>
                                    {item?.longDescription?.map(
                                      (description, index) => (
                                        <React.Fragment key={index}>
                                          {description?.type === "ul" && (
                                            <ul>
                                              {description?.children?.map(
                                                (item, itemIndex) => (
                                                  <li key={itemIndex}>
                                                    {item.children[0].text}
                                                  </li>
                                                )
                                              )}
                                            </ul>
                                          )}
                                          {description &&
                                            description?.children?.map(
                                              (child, childIndex) => (
                                                <React.Fragment
                                                  key={childIndex}
                                                >
                                                  {child?.bold && (
                                                    <strong>
                                                      {child?.text}{" "}
                                                    </strong>
                                                  )}
                                                  {!child?.bold && (
                                                    <span>{child?.text}</span>
                                                  )}
                                                </React.Fragment>
                                              )
                                            )}
                                        </React.Fragment>
                                      )
                                    )}
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>
                  </div>
                </section>
              );
            }
          )}
          {dataEngineeringDataset?.dataPreparationCycle?.map(
            (dataset, dataIndex) => {
              return (
                <div
                  key={dataIndex}
                  className="datapreparation_main_section_sub"
                >
                  <div className="datapreparation_main_section">
                    <div className="dataprepare_sub_lft">
                      <img
                        className="img-fluid"
                        src={dataset?.dataPreparationCycleImage?.url}
                        alt="data_preparation_cycle_bg_img"
                      />
                    </div>
                    <div className="dataprepare_rht_setion data_preparation_cycle_rht">
                      <div className="data_cycle_box_main">
                        {dataset?.dataPreparationCycleSection?.map(
                          (item, index) => {
                            return (
                              <div key={index} className="data_cycle_box">
                                <h2>{item?.title}</h2>
                                <p>{item?.shortDescription}</p>
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
          )}
          {dataEngineeringDataset && (
            <section className="devoops_tech data-preparation_tech real_tech_sec">
              {
                id === 'data-preparation' ?
                  dataEngineeringDataset?.dataEnggTechStack?.map((dataset, dataIndex) => {
                    return (
                      <div key={dataIndex} className="container">
                        <h2>{dataset?.heading}</h2>
                        {dataset && dataset?.technologyStackSection?.map((stackData, stackIndex) => {
                          return (
                            <div key={stackIndex} className="new_real_tech_logo_main">
                              <div>
                                <h3 className="stack_sub_head">{stackData?.title}</h3>
                                <ul className="d-flex">
                                  {stackData?.stackImages?.length && stackData?.stackImages?.map((item, index) => {
                                    return (
                                      item?.sectionImg?.url &&
                                      <li key={index}>
                                        {" "}
                                        <img
                                          className="img-fluid"
                                          src={item?.sectionImg?.url}
                                          alt="tect_logo"
                                        ></img>
                                        <span>{item.name}</span>
                                      </li>
                                    )
                                  })}
                                </ul>
                              </div>
                              {stackData?.stackImages[0]?.subTechnologyStackSection[0]?.subStackImages.length && stackData?.stackImages?.map((item, index) => {
                                return (
                                  <div key={index}>
                                    <div className="col-lg-12 devoops_tech_flex">
                                      <h6>{item?.name}</h6>
                                      <ul className="d-flex">
                                        {item?.subTechnologyStackSection?.map((subItem) => {
                                          return (
                                            subItem?.subStackImages?.map((imageData, imageIndex) => {
                                              return (
                                                imageData && imageData?.sectionImg?.url &&
                                                <li key={imageIndex}>
                                                  <img
                                                    className="img-fluid"
                                                    src={imageData?.sectionImg?.url}
                                                    alt="tect_logo"
                                                  ></img>
                                                  <span>{imageData.name}</span>
                                                </li>
                                              )
                                            })
                                          )
                                        })}
                                      </ul>
                                    </div>
                                  </div>
                                )
                              })}
                            </div>
                          )
                        })}
                      </div>
                    )
                  }) :
                  <TechnologyStack
                    dataset={dataEngineeringDataset && dataEngineeringDataset}
                  />
              }
            </section>
          )}
          <ConversationSection />
        </div>
      )}
      {scrollActive && (
        <div className="slide_up_arrow_sec">
          <button onClick={handleLoadMore}>
            {" "}
            <img
              className="img-fluid"
              src={getUpArrowSrc}
              alt="up_slide_arrow"
            ></img>
          </button>
        </div>
      )}
    </div>
  );
};

export default MainDataEngineering;
