import React from "react";
import CommonContactForm from "../Forms/CommonContactForm";

const LetsTalk = ({ letsTalkVisible, handleCancel, pageId, pageTitle}) => {
  return (
    <div className="lets_talk_modal">
      {letsTalkVisible && (
        <>
          <section className="home_form_setion marbtm0 lets_talk_bg">
            <div className="lets_talk_body letstalk_pad0">
                  <div className="letstalk_new_form_rht">
                    <div className="lets_talk_newform_rht">
                      <div className="modelHeader">
                        <h5>Send a message</h5>
                        <div className="lets_talk_close_div">
                          <button onClick={handleCancel}>X</button>
                        </div>
                      </div>
                      <CommonContactForm
                        pageId = {pageId}
                        pageTitle= {pageTitle}
                        handleCancel={handleCancel} 
                      />
                    </div>
                  </div>
            </div>
          </section>
        </>
      )}
    </div>
  );
};

export default LetsTalk;
