import React from "react";

const TechnologyStack = ({ dataset }) => {
  return (
    <div className="container cloud_native_tech">
      {dataset?.technologyStackWeUse?.map((dataset, dataIndex) => (
        <div key={dataIndex}>
          <h2>{dataset.heading}</h2>
          <div className="row devoops_tech_sec">
            {dataset?.technologyStackSection?.map((stackDataset, stackIndex) => (
              <div key={stackIndex} className="col-lg-12 devoops_tech_flex">
                <h6>{stackDataset?.title}</h6>
                <ul>
                  {
                    stackDataset?.stackImages?.map((item, index) => (
                      <li key={index}>
                        <img className="img-fluid" src={item?.sectionImg?.url} alt="sectionImg" />
                        <span>{item?.name}</span>
                      </li>
                    ))
                  }
                </ul>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  )
}

export default TechnologyStack;