import React from 'react'

export const ServiceBanner = ({ dataset }) => {
  return (
    <div>
      <section
        className="banner_section aboutus_new_banner_section"
        style={{
          backgroundImage:
            "url(" + dataset?.bannerImage?.url + ")",
        }}
      >
        <div className="banner_main aboutus_new_banner">
          <div className="container">
            <div className="row w100">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <h1 className="aboutnew_ban_head">
                  {dataset?.title}
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
